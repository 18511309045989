import { styles } from '../style/style';

import ContractLine from '../Components/ContractLine';

import { useState, useEffect, useRef, useCallback } from "react";
import plsData from "../contracts/plsHolders.json";
import { timeDividendContract } from '../web3/Provider';

export default function MyTimeFee() {

	let wallAddress = '0x0000000000000000000000000000000000000000';
	let isRunBalance = true;

	const postAbi = useRef('..Loading');

	const fetchData = useCallback(async (methodName: string, walletAddress: string) => {
		switch(methodName) {
			case 'name':
				const nameData = await timeDividendContract.methods.name().call();
				setName(nameData);
			  break;
			  case 'totalSupply':
				const supplyData = await timeDividendContract.methods.totalSupply().call();
				setTotalSupply(supplyData.toString());
			  break;			  
			  case 'balanceOf':
				const balanceData = await timeDividendContract.methods.balanceOf(walletAddress).call();
				setBalanceOf(balanceData.toString());
			  break;
			  case 'claimableDividendOf':
				postAbi.current = await timeDividendContract.methods.claimableDividendOf(walletAddress).call();
				setClaimReadyDividend(postAbi.current);
			  break;
			  case 'cumulativeDividendClaimed':
				postAbi.current = await timeDividendContract.methods.cumulativeDividendClaimed(walletAddress).call();
				setClaimedDividend(postAbi.current);
			  break;					  	  
			default:
			  setName('error');
			}
		}, []
	);

	const [ getName, setName ] = useState('');
	useEffect(() => {
		fetchData('name', wallAddress);
	},[fetchData, wallAddress]);
	const [getTotalSupply, setTotalSupply] = useState('0');
	useEffect(() => {
		fetchData('totalSupply', wallAddress);
	},[fetchData, wallAddress]);
	const [getBalanceOf, setBalanceOf] = useState('0');
	useEffect(() => {
		fetchData('balanceOf', wallAddress);
	},[fetchData, wallAddress]);
	const [getClaimedDividend, setClaimedDividend] = useState('0');
	useEffect(() => {
		fetchData('cumulativeDividendClaimed', wallAddress)
	},[fetchData, wallAddress]);
	const [getClaimReadyDividend, setClaimReadyDividend] = useState('0');
	useEffect(() => {
		fetchData('claimableDividendOf', wallAddress)
	},[fetchData, wallAddress]);

	const [query, setQuery] = useState("")

	function formatBigNumber(bigNumber: bigint | string, precision: number ) {
		let bigNumber_wip = bigNumber.toString();
		const bigNoLen = bigNumber_wip.length;  
			if (bigNoLen <= precision) {
				let addZeros = '0.' 
				for(let i = 0; i < (precision - bigNoLen); i++) {addZeros = addZeros + '0';};
				bigNumber_wip = addZeros + bigNumber_wip;	
			} else {
				bigNumber_wip = bigNumber_wip.substring(0,(bigNoLen - precision)) + '.' 
				+ bigNumber_wip.substring((bigNoLen - precision), (bigNoLen)); 
			}
	        		 
			return bigNumber_wip;
	}
	 
  return (
  	<styles.Container>
		<styles.BannerTitle>Available on PLS</styles.BannerTitle>
		<styles.GeneralParagragh>
			Start typing, when you find the address you are looking for, copy it into the input box. You will see the stats for that address. 
		</styles.GeneralParagragh>		
		<div>
   			<input placeholder="Start typing your address" onChange={event => setQuery(event.target.value)}/>
   		</div>
		<styles.WalletAddressList>
			{
				plsData.result.filter(postContract => {
					if (query.length === 42) {
						if (isRunBalance){
							wallAddress = query;
							fetchData('balanceOf', wallAddress);
							fetchData('cumulativeDividendClaimed',wallAddress);
							fetchData('claimableDividendOf', wallAddress);
							isRunBalance = false;
						}
					} else if (query.length !== 42){
						isRunBalance = false;
					}

					if (query === "") {
						return postContract;
					} else if (postContract.address.toLowerCase().includes(query.toLowerCase())) {
						return postContract;
					}
					return "";
				}).map((postContract) => (
   					<styles.WalletAddressListItem key={postContract.address}>
   						{postContract.address}
   					</styles.WalletAddressListItem>
  				))
			}
		</styles.WalletAddressList>			
		<styles.GeneralParagragh>{getName}</styles.GeneralParagragh>	
		<styles.BannerSubTitle>Check your Time dividend</styles.BannerSubTitle>
		<styles.GeneralParagragh>
			You can check out what addresses have {getName}.  
			At the moment you can see data for the PLS chain.
		</styles.GeneralParagragh>		
	    <styles.BannerSubTitle>The Future of Finance</styles.BannerSubTitle>
	    <styles.InformationText>
            Earn Passive Income - Free Your Time
        </styles.InformationText>
		<ContractLine 
			title="Total Burned" 
			tooltip="Total amount of TIME burned by the Buy and Burn contract." 
			callValue={ (2000000000 - parseFloat(formatBigNumber(getTotalSupply, 18))).toString()  } 
			currency="PLS"
		/>
		<ContractLine 
			title="Total Supply" 
			tooltip="Total Supply at this time. This value can only fall." 
			callValue={formatBigNumber(getTotalSupply, 18)} 
			currency="PLS"
		/>
		<ContractLine 
			title="This address - T.I.M.E. Dividend deposit" 
			tooltip="Total TIME this address has on this chain." 
			callValue={ formatBigNumber(getBalanceOf, 18) } 
			currency="PLS"
		/>
		<ContractLine 
			title="PLS claimed from this address" 
			tooltip="Total amount of PLS this address has claimed." 
			callValue={ formatBigNumber(getClaimedDividend, 18)} 
			currency="PLS"
		/>
		<ContractLine 
			title="PLS this address has to Claim" 
			tooltip="Total amount of PLS not claimed but is ready for this address to claim." 
			callValue={ formatBigNumber(getClaimReadyDividend, 18)} 
			currency="PLS"
		/>
 	</styles.Container>
	
  )
}
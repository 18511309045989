import { styles } from '../style/style';
import { useState } from "react";
import emailjs from "emailjs-com";

interface ButtonState {
  color: string;
  text: string;
}

interface InputState {
  displayText: string;
  errorText: string;
}

export default function Home() {

  const createMarkup = (text: any) => {
		return {
		  __html: text
		};	
	};

  const [inputName, setInputName] = useState<InputState>({
    displayText: '',
    errorText: ''
  });
  const [inputContact, setInputContact] = useState<InputState>({
    displayText: '',
    errorText: ''    
  });
  const [inputChatText, setInputChatText] = useState<InputState>({
    displayText: '',
    errorText: ''    
  });
  const [sentText, setSentText] = useState("");
  const [buttonState, setButtonState] = useState<ButtonState>({
    color: 'blue',
    text: 'Submit'
  });  

  const handleSubmit =(event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let textValidation = true;
    if(inputName.displayText === ""){
      textValidation = false;
      setInputName({displayText:'', errorText: '* Please Enter a Name' });
    }
    if(inputContact.displayText === ""){
      textValidation= false;
      setInputContact({displayText:'', errorText: '* Please Enter a Contact' });
    }
    if(inputChatText.displayText === ""){
      textValidation = false;
      setInputChatText({displayText:'', errorText: '* Please Enter a Chat Message' });
    }
    let messageText = "Thank you for getting in touch.<br>";
    messageText = messageText + "Contact Name: " + inputName.displayText + "<br>";
    messageText = messageText + "Your Contact Details:" + inputContact.displayText + "<br>"; 
    messageText = messageText + "Your Message:" + inputChatText.displayText;
    if(buttonState.color === "blue" && textValidation) {
      setButtonState({color: "red", text: "Re Set"});
      console.log( messageText );
      emailjs.send("service_bin5u6e","template_afrn6ar",{
        message: messageText,
        },"zVyx7wNxPNLH_ThMk")
        .then(function(){
          setSentText(messageText);
        }, function(error){
          setSentText("Sorry about this but an error occured");
        });
        setInputName({displayText: '', errorText: ''});
        setInputContact({displayText: '', errorText: ''});
        setInputChatText({displayText: '', errorText: ''});
    } else if(buttonState.color === "red")  {
      setButtonState({color: "blue", text: "Submit"});
      setInputName({displayText: '', errorText: ''});
      setInputContact({displayText: '', errorText: ''});
      setInputChatText({displayText: '', errorText: ''});
      setSentText("");
    }
  }


  return (
    <styles.Container>
        <styles.BannerTitle>Mr Gunn's Crypto Dividend Store</styles.BannerTitle>
        <styles.GeneralParagragh>
          We are part of the Internet Money community. 
          Please take the time to visit their site. 
          We think you will love it over there! 
        </styles.GeneralParagragh>
        <styles.InternetMoneyLink href='https://www.internetmoney.io'>
          <span className="coin coin-left"></span>Internet Money
        </styles.InternetMoneyLink>
        <styles.GeneralParagragh> 
          If you do, come back to us. Let us lay out what we are going to do.
        </styles.GeneralParagragh>
        <styles.BannerSubTitle>The Future of Finance</styles.BannerSubTitle>
        <styles.InformationText>Earn Passive Income - Free Your Time</styles.InformationText>
        <styles.BannerSubTitle>Thanks for coming back</styles.BannerSubTitle>
        <styles.GeneralParagragh>
          You may know by now how cool the Internet Money wallet is giving you these amazing benefits.
          <ul>
            <li>It is easy to use.</li>
            <li>You can swap tokens at competitive rates for a nominal fee of 0.79%.</li>
            <li>You can purchase TIME Dividend tokens.</li>
            <li>You can start earning dividends.</li>
            <li>The dividend is paid in the native token (ETH, BSN, PLS).</li>
          </ul>  
          We highly recommend that you hold your TIME Dividends on the Internet Money wallet 
          and claim your dividends for free there.
        </styles.GeneralParagragh>
        <styles.BannerSubTitle>Use Mr Gunn’s Dividend Store to claim your Dividend</styles.BannerSubTitle>
        <styles.GeneralParagragh>
          Not everyone wants to hold their crypto on the Internet Money wallet 
          and we want to help out there. 
          In short, we are building a service where you can claim your share of the dividend pool
          directly from this site. 
          We will charge you a rate of 0.369% from the native tokens you claim for using this service.
        </styles.GeneralParagragh>
        <styles.BannerSubTitle>
        So that's great but who gets the 0.369% earned from Mr Gunn’s Crypto Dividend Store?
        </styles.BannerSubTitle>
        <styles.GeneralParagragh>
          Great question and you may like our answer. 
          We want to pay this to our MrGunnTimeDividend token holders just like the Time Dividend.          
        </styles.GeneralParagragh>
        <styles.BannerSubTitle>How to get the MrGunnTimeDividend token?</styles.BannerSubTitle>
        <styles.GeneralParagragh>
        When we are ready to launch the token we will mint 2.22 billion tokens  
        <ul>
          <li>200 million tokens will be used for liquidity.</li>
          <li>20 million tokens will be used to pay for the upkeep of the site.
              How we use the liquidity and site up dates will be reported on.</li>
          <li>This is the exciting part! 
              2 billion tokens will be air dropped to holders of the Internet Money FUTURE time tokens.</li> 
          <li>1 FUTURE = 1 MrGunnTimeDividend.</li>
        </ul>        
        </styles.GeneralParagragh>
        <styles.GeneralParagragh>
          The good people at Internet Money created the FUTURE Time token. 
          So holders will get TIME Dividend tokens on any new blockchain
          that the Internet Money wallet is launched on. Currently they have plans to launch on the Polygon network.      
        </styles.GeneralParagragh>
        <styles.GeneralParagragh>
          With every new project Mr Gunn bring to market 
          we will air drop tokens on the same model from the FUTURE Time token, as described above.
        </styles.GeneralParagragh>

        <styles.BannerTitle>Get in touch</styles.BannerTitle>
      <styles.BannerSubTitle>
        Please feel free to fill out some basic contact details so I can get back to you for a chat.
      </styles.BannerSubTitle> 
      <styles.GeneralParagragh dangerouslySetInnerHTML={createMarkup(sentText)}></styles.GeneralParagragh>
      <styles.GeneralParagragh>Contact Name</styles.GeneralParagragh>
      <div>
        <styles.ErrorMessage>{inputName.errorText}</styles.ErrorMessage>
        <input 
          type="text" 
          onChange={(e) => setInputName({displayText: e.target.value, errorText: ''})}
          value={inputName.displayText}  
        />
      </div>
      <styles.GeneralParagragh>Email, Telegram or other form of contact address</styles.GeneralParagragh>
      <div>
      <styles.ErrorMessage>{inputContact.errorText}</styles.ErrorMessage>
        <input 
          type="text" 
          onChange={(e) => setInputContact({displayText: e.target.value, errorText: ''})}
          value = {inputContact.displayText}
        />
      </div>              
      <styles.GeneralParagragh>Any message to get started </styles.GeneralParagragh>
      <div>
      <styles.ErrorMessage>{inputChatText.errorText}</styles.ErrorMessage>
        <textarea 
          rows={4}
          cols={40}
          onChange={(e) => setInputChatText({displayText: e.target.value, errorText: ''})} 
          value={inputChatText.displayText}
        />
      </div>    
      <div>
        <styles.SendMessageButton color={buttonState.color} onClick={handleSubmit}>
          {buttonState.text}
        </styles.SendMessageButton>
      </div>

    </styles.Container>	
  )
}
import React, { useEffect, useState } from "react";
import { styles } from '../style/style';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title,CategoryScale, Legend } from 'chart.js';
import axios from "axios";
import { Line } from "react-chartjs-2";

export default function LineCharts() {
  
    Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend);


    interface ITimeDividendChart {
        date: string;
        pls: string;
        time: string;
        return: string;

      }

    let data: ITimeDividendChart[];
    let public_Url = "https://api.mrgunn.io/v1/charts"  

    const fetchData = async() => {
    
      try {
        const response = await axios.get(public_Url +"/TIMEChartData");
        data = response.data;
        console.log(data);
        return data;
      } catch (err) {
        console.log(err);
      }
    };

    const [dailyData, setDailyData] = useState<ITimeDividendChart[]>();
      
    const fetchApi = async () => {
        const data = await fetchData();
        setDailyData(data);
    };
      
    useEffect(() => {
        fetchApi();
    });

const thisTest =  dailyData;
if (!Array.isArray(dailyData) && thisTest !== undefined)
{
  console.log("Array");
}

    const lineChart = dailyData ? (
        <Line
          data={{
            labels: dailyData.map( row => row.date ),
            datasets: [
              {
                data: dailyData.map( row => (row.pls)),
                label: "PLS $ value",
                borderColor: "rgb(0, 217, 255)",
                fill: true,
              },
              {
                data: dailyData.map( row => (+row.time / 18).toString()),
                label: "TIME Ratio",
                borderColor: "red",
                backgroundColor: "rgba(255, 0, 0, 0.3)",
                fill: true,
              },
              {
                data: dailyData.map( row => (+row.return / 32000).toString()),
                label: "Dividend Rewards Ratio",
                borderColor: "green",
                fill: true,
              },          
            ],
          }}
        />
      ) : null;    

    return (
        <styles.Container>
        <styles.BannerTitle>
          TIME and PLS v Dividend $ Value
        </styles.BannerTitle>
        <styles.GeneralParagragh>
          The Green line tracks the value of TIME dividends paid. 
          The Red line tracks the TIME Dividend price. 
          The Blue line tracks the PLS price in $.
          Both the TIME Dividend and the $ value of the dividends are shown as a relationship to PLS  
        </styles.GeneralParagragh>
          <div>{lineChart}</div>
        </styles.Container>
    )
}
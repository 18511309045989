import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import {styles} from './style/style';

import Home from './routes/Home';
import MyTimeFee from './routes/MyTimeFee';
import About from './routes/About';
import DevLicenceClub from './routes/DevLicenceClub';
import Cipher from './routes/Cipher';
import LineCharts from './routes/LineCharts';

export default function SiteNav() {

    return(
        <Router>
            <styles.Header>
                <styles.NavBar>
                    <Link to="/">
                        <styles.NavButton color="#fba81a">Home</styles.NavButton>
                    </Link>
                    <Link to="/about">
                        <styles.NavButton color="#fba81a">About</styles.NavButton>
                    </Link>
                    <Link to="/myTimeFee">
                        <styles.NavButton>What's My Time Dividend</styles.NavButton>
                    </Link>
                    <Link to="/cipher">
                        <styles.NavButton>cipher</styles.NavButton>
                    </Link>
                    <Link to="/lineCharts">
                        <styles.NavButton>Charts</styles.NavButton>
                    </Link>                              
                </styles.NavBar>
            </styles.Header>
            {/* 👇️ Wrap your Route components in a Routes component */}
            <styles.BodySection>
                <Routes>
                    <Route path="/about" element={<About />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/myTimeFee" element={<MyTimeFee />} />
                    <Route path="/devLicenceClub" element={<DevLicenceClub/>} />
                    <Route path="/cipher" element={<Cipher/>} />
                    <Route path="/lineCharts" element={<LineCharts/>} />
                </Routes>
            </styles.BodySection>
        </Router>
    )
}
export const TimeDividendEthAbi = [
    {
        "inputs":[
            {
                "internalType":"uint256",
                "name":"supply",
                "type":"uint256"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"constructor"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"owner",
                "type":"address"
            }
        ],
        "name":"HasAdmin",
        "type":"error"
    },
    {
        "inputs":[],
        "name":"OutOfBounds",
        "type":"error"
    },
    {
        "inputs":[],
        "name":"SupplyMissing",
        "type":"error"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"owner",
                "type":"address"
            },
            {
                "indexed":true,
                "internalType":"address",
                "name":"spender",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"value",
                "type":"uint256"
            }
        ],
        "name":"Approval",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"account",
                "type":"address"
            },
            {
                "indexed":true,
                "internalType":"address",
                "name":"recipient",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"ClaimDividend",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"from",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"weiAmount",
                "type":"uint256"
            }
        ],
        "name":"DistributeDividend",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"previousOwner",
                "type":"address"
            },
            {
                "indexed":true,
                "internalType":"address",
                "name":"newOwner",
                "type":"address"
            }
        ],
        "name":"OwnershipTransferred",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"from",
                "type":"address"
            },
            {
                "indexed":true,
                "internalType":"address",
                "name":"to",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"value",
                "type":"uint256"
            }
        ],
        "name":"Transfer",
        "type":"event"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"account",
                "type":"address"
            }
        ],
        "name":"accumulativeDividendOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"owner",
                "type":"address"
            },
            {
                "internalType":"address",
                "name":"spender",
                "type":"address"
            }
        ],
        "name":"allowance",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"spender",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"approve",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"account",
                "type":"address"
            }
        ],
        "name":"balanceOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"burn",
        "outputs":[],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"account",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"burnFrom",
        "outputs":[],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address payable",
                "name":"recipient",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"claimDividend",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"account",
                "type":"address"
            }
        ],
        "name":"claimableDividendOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"max",
                "type":"uint256"
            }
        ],
        "name":"clamp",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"pure",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "name":"cumulativeDividendClaimed",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"decimals",
        "outputs":[
            {
                "internalType":"uint8",
                "name":"",
                "type":"uint8"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"spender",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"subtractedValue",
                "type":"uint256"
            }
        ],
        "name":"decreaseAllowance",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"uint256",
                "name":"magDividendPerShare",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"balance",
                "type":"uint256"
            },
            {
                "internalType":"int256",
                "name":"correction",
                "type":"int256"
            }
        ],
        "name":"dividendFrom",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"claimableDividend",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"productRemainder",
                "type":"uint256"
            }
        ],
        "stateMutability":"pure",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"spender",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"addedValue",
                "type":"uint256"
            }
        ],
        "name":"increaseAllowance",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "name":"magnifiedDividendCorrections",
        "outputs":[
            {
                "internalType":"int256",
                "name":"",
                "type":"int256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"magnifiedDividendPerShare",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"magnitude",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"bytes[]",
                "name":"data",
                "type":"bytes[]"
            }
        ]
        ,"name":"multicall",
        "outputs":[
            {
                "internalType":"bytes[]",
                "name":"results",
                "type":"bytes[]"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"name","outputs":[
            {
                "internalType":"string",
                "name":"",
                "type":"string"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"owner",
        "outputs":[
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"renounceOwnership",
        "outputs":[],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"symbol",
        "outputs":[
            {
                "internalType":"string",
                "name":"",
                "type":"string"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"totalSupply",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"to",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"transfer",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"from",
                "type":"address"
            },
            {
                "internalType":"address",
                "name":"to",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            }
        ],
        "name":"transferFrom",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":
        [
            {
                "internalType":"address",
                "name":"newOwner",
                "type":"address"
            }
        ],
        "name":"transferOwnership",
        "outputs":[],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "stateMutability":"payable",
        "type":"receive"
    }
] as const;
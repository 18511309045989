import { styles } from '../style/style';
import devLogo from '../images/blockchain.jpg'
export default function About() {

  return (
    <styles.Container>
      <styles.BannerTitle>A lone star with an "IDEAS MAN"</styles.BannerTitle>
      <styles.GeneralParagragh>
        I am Jon a software developer from the web 2 age. 
        I will be honest the move from web 2 to web 3 is a tough one. 
        Add in being a lone star makes it a real challenge.
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        I got into this site because the "IDEAS MAN" got me thinking, I can give this a go. 
        A real clue to who he is in the site name. 
        I have not asked for his permission to add his name here 
        so I will wait for that before adding it.
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        We are going to be adding more
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        We are thinking of good ideas, developing them and getting them out there. 
        You hold FUTURE tokens and will take advantage of what we bring to the community.        
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>I am active in the Internet Money Telegram chat please feel free to contact me in there. I will get back to you.</styles.BannerSubTitle>
     
      <styles.DevLicenceClub href='/devLicenceClub'>
      <img src={devLogo} alt="Code Logo" width="50" height="50" />Code Sharing Licences<img src={devLogo} alt="Code Logo" width="50" height="50" />
        </styles.DevLicenceClub>
      <styles.GeneralParagragh>
        As I develop this bespoke dapp and face issues that require solutions, I writing code to meet these needs.
        Visitors to this site may wish to take advantage of the tested coded solution demostrated on this site. 
        I am offering licenced access to code modules on this site. 
        I do not intend this to be the main income from this dapp to come from these licences but it will start to provide income.
        I will be reflecting the cost of development in the licencing model. So costs will be low.
      </styles.GeneralParagragh>
    </styles.Container>
  )
}
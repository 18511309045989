import {styles} from '../style/style';
import basicCipher from '../videos/basiccipher.mp4';
import detailCipher from '../videos/cipherdescription.mp4';

export default function Cipher() {

  return (
    <styles.Container>
      <styles.BannerTitle>
        Seed Word Backup Cipher
      </styles.BannerTitle>
        <video width="320" height="240" controls >
          <source src={basicCipher} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      <styles.BannerSubTitle>
        Keep your crypto SAFE
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        Whenever anyone holds crypto they need a public address where their tokens are stored on the blockchain. 
        This address is accessed via a private key, this key can be represented with a seed word. The seed word 
        is generated for the address. 
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        BIP39
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        BIP39 is the protocol that is used to record the seed words. This word is 12 or 24 words 
        selected for the user from a list of 2048 words.   
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        The Internet Money wallet
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        You can enter the stored seed words into the wallet to recover your crypto. 
        We can write the words down and store them safely to be used when required.
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        Understanding how difficult it is to guess a seed word with only 2 words
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        Think of 2 seed words rubber and detect. to guess these words from the list of 2048 words you would need 
        to try each combination <b>4,192,256</b> times to check all the combinations. It could be that half that will 
        give a hacker a good chance of guessing the combination.
      </styles.GeneralParagragh>  
      <styles.BannerSubTitle>
        The maths of a 12 word, seed words
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        Using 12 words will now give a truly huge number of possibilities.
        <ul>
            <li>
                2048 x 2047 x 2046 x 2045
            </li>
            <li>
                2044 x 2043 x 2042 x 2041
            </li>
            <li>
                2040 x 2039 x 2038 x 2037
            </li>                        
        </ul>
        Giving 5.271537971301 5 followed by 51 zeros is impossible to guess.
      </styles.GeneralParagragh>     
      <styles.BannerSubTitle>
        Get a hardware wallet
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        It is good practice to have a hardware wallet. You can plug it in and you have access to your wallet. 
        In addition to the basic plug and play feature to a hardware wallet you can add a passphrase. 
        This is the seed of this idea.
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        Defi is up to you!
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        Crypto self custody is a two edged sword. 
        <ul>
          <li>
            You are in full control of your crypto. Giving you financial freedom.
          </li>
          <li>
            You are in full control of your crypto. If you lose it no one can help you.
          </li>
        </ul>
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>  
        Writing down and storing your seed words securely is important. you can stamp the words on something 
        like a steel plate, only the first 4 char are required.
      </styles.GeneralParagragh> 
      <styles.GeneralParagragh>
        e.g rubb dete for rubber and detect.
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>  
        Every one who knows about securing your crypto will tell you to get a hardware wallet. 
        You connect it to your wallet, keep it safe. and don't lose it. 
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>  
        To me that is not enough tools and we should always look to make keeping peoples keys safe. 
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        Mnemonic help you remember a passphrase
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        
        They are all sorts of stuff to help you remember a passphrase. I just looked out of the window 
        and I saw a blue sky. So I thought about the ELO song Mr Blue sky..
        <ul>
          <li>
            Sun is shinin' in the sky
          </li>
          <li>
            There ain't a cloud in sight
          </li>
          <li>
            It's stopped rainin', everybody's in the play
          </li>
          <li>
           And don't you know
          </li>
          <li>
            It's a beautiful new day? Hey
          </li>
        </ul>
          So can we add a rule or two about replacing spaces etc. Then we can imagine a memorable passphrase. 
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        What am I asking you to remember?
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
      <ul>
          <li>
            A passphrase (20 or more characters)
          </li>
          <li>
            A Team name (The team will have published fixutes each year 26 or more per year)
          </li>
          <li>
            A year that team has played in.
          </li>
          <li>
            A month of the year.
          </li>
        </ul>
      </styles.GeneralParagragh>     
      <styles.BannerSubTitle>
        How difficult is the cipher to hack?
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        The code is designed to be incredibly difficult to guess the seed word from the cipher resulting text. 
        This is explored further down in this page. I estimate that the passphrase (20 characters) 
        ,teams ,year and month. will give 10 power 40.  
      </styles.GeneralParagragh>      
      <styles.BannerSubTitle>
        Storing your cipher text 
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        The code is disigned to be increiblely difficult to guess the seed word from the cipher resulting text. 
        This is explored further down in this page. I estimate that the passphrase (20 characters) ,teams ,year and month.
        will give 10 power 40.   
      </styles.GeneralParagragh>  
      <styles.BannerSubTitle>
        How to store your cipher
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        <ul>
          <li>
            Download the cipher program from mrgunn.io
          </li>
          <li>
            Run the program on your pc
          </li>
          <li>
            Setup your passphrase, Team, Year,Month (Not recorded)
          </li>
          <li>
            Enter your seed word (Not recorded)
          </li>
          <li>
            Your cipher is stored on the Pulsechain or you can copy it and keep it yourself. 
          </li>
        </ul>
      </styles.GeneralParagragh>   
      <styles.BannerSubTitle>
        How to recover your seed words
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        <ul>
          <li>
            Open the cipher program your got from mrgunn.io
          </li>
          <li>
            Run the program on your pc
          </li>
          <li>
            Enter your passphrase, Team, Year,Month (Not recorded)
          </li>
          <li>
            Your cipher is recovered from the Pulsechain. If you stored it locally enter your cipher text
          </li>
          <li>
            View your seed words (Not recorded)
          </li>
        </ul>
      </styles.GeneralParagragh>
      <styles.BannerTitle>
        CIPHER TECHNICAL DESCRIPTION
      </styles.BannerTitle>
      <video width="320" height="240" controls >
          <source src={detailCipher} type="video/mp4" />
          Your browser does not support the video tag.
        </video>    
      <styles.BannerSubTitle>
        Overview of seed word to cipher creation
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        <ul>
          <li>
            The seed word become 30 words.
          </li>
          <li>
            An alphabet is created with complexity.
          </li>
          <li>
            The 30 words produced from the seed word is transformed into the cipher.
          </li>
          <li>
            Show it to the User.
          </li>
          <li>
            Ask if the user wants the cipher stored on the Pulsechain.
          </li>
          <li>
            If Yes transform the password into a cipher.
          </li>
          <li>
            Split the cipher into 30 parts and mix them up.
          </li>
          <li>
            Create nft metadata.
          </li>
          <li>
            With name passphrase.
          </li>
          <li>
            With Description a pointer to position 1.
          </li>
          <li>
            Create sets of nft's with metadata name next position, description cipher part. 
          </li>
        </ul>
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        Overview of cipher to seed words  
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        <ul>
          <li>
            All the data below has been processed by the cipher..
          </li>
          <li>
            Look for the passphrase from the first nft list.
          </li>
          <li>
            If not found ask the user to enter there local cipher.
          </li>
          <li>
            If found find the position 1 matadata nft from the 30 nft list.
          </li>
          <li>
            Read the Cipher part and find the new position from the next nft until all the cipher is returned.
          </li>
          <li>
            The alphabet from above is recreated .
          </li>
          <li>
            The cipher using the alphabet is transformed back to the 30 word seed word list.
          </li>
          <li>
            The 30 word seed word list is transformed back to the original seed words.
          </li>
        </ul>
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        The possible combinations of the 30 word list
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        265,252,859,812,191,058,636,308,480,000,000.   
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        From the list of the bIP39 words remove the words used in the seed words then pick a number from 
        1 to bip39 - seed word then make a list of words from 1 to 24. Then from that list pick the word 
        that is in the position for the number of words in the seed word list. The code adds in the remaining words. 
      </styles.GeneralParagragh>  
      <styles.BannerSubTitle>
        The cipher alphabet
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        Each letter of the alphabet is represented by a pair of printable characters from the ascii list. 
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        !#$%&&apos;()*+,-./0123456789:;&gt;=&lt;?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_&amp;&apos;&#768;abcdefghijklmnopqrstuvwxyz&#123;|&#125;~
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        At a very simple level a = !# b = $% c = &' and on to z = TU. 
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        We have enough characters to have 114 pairs per letters of the alphabet. 
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        To ensure no char pairs are used twice per letter you need 63 pairs.  
      </styles.GeneralParagragh>
      <styles.GeneralParagragh>
        To generate the list for each letter of the alphabet. Large numbers are passed
        into the mix up code. The ascii set of printable characters are mixed up the positions 92 91 90 89 
        are placed in a place on the list that can have a 150,000,000 variation. So 92 could go in position 
        45, 91 14, 90 65, 89 78 for example then do the same for 88 87 86 85 excluding the position already filled. 
      </styles.GeneralParagragh> 
      <styles.BannerSubTitle>
        Create the cipher from the letter pools
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        A basic example of a letter pool alphabet. 
        <ul>
          <li>
            a
            <ul><li>j% £C &^ 7M |( @+</li></ul>
          </li>
          <li>
            b
            <ul><li>%j C£ ^& M7 |( @+</li></ul>
          </li>
          <li>
            c
            <ul><li>j£ £% &M 7^ |+ @)</li></ul>
          </li>
          <li>
            d
            <ul><li>£j %£ M& ^7 (| @+</li></ul>
          </li>
          <li>
            e
            <ul><li>£7 j^ %& ^j |( @+</li></ul>
          </li>
        </ul>        
        Some words that can be created with the cipher alaphbet.
        <ul>
          <li>
            dee
                        <ul><li>(|£7@+</li></ul>
          </li>
          <li>
            ced
            <ul><li>7^%&(|</li></ul>
          </li>
        </ul>        
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        Split the cipher and write the nft data
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        Using the shuffle logic a cipher is created and writes the positions to the nft metadata. The longer the user give the write phase 
        the more it is spred out in the metadata.   
      </styles.GeneralParagragh>      
    </styles.Container>
  )
}
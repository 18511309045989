import MyTimeFee from '../routes/MyTimeFee';
import { styles } from '../style/style';

import { TimeDividendEthAbi } from '../contracts/TimeDividendEthAbi';
import Web3 from "web3";
import { useState } from 'react';

  const web3 = new Web3("wss://pulsechain.publicnode.com");
  // Contract address of the deployed smart contract
  const contractAddress = "0xCA35638A3fdDD02fEC597D8c1681198C06b23F58";
  export const timeDividendContract = new web3.eth.Contract(TimeDividendEthAbi, contractAddress);
  
export default function Provider() {
  const options = [
    { chainName: "PLS", contract: "0xpls" },
    { chainName: "ETH", contract: "0xeth" },
    { chainName: "BSC", contract: "0xbsc" }
    ];

  const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
  
	const toggling = () => setIsOpen(!isOpen);
  
	const onOptionClicked = (chainName: string, contract:string) => () => {
	  setSelectedOption(chainName);
	  setValue(contract);
	  setIsOpen(false);
	  console.log(selectedOption);
	};	

	const [value, setValue] = useState("");	
  return (
    <styles.Container>
		<h1>Pick Chain</h1>
      		<styles.DropDownContainer>
        		<styles.DropDownHeader onClick={toggling}>
          			{selectedOption || "PLS"}
        		</styles.DropDownHeader>
        		{isOpen && (
          			<styles.DropDownListContainer>
            			<styles.DropDownList>
              				{options.map((option) => (
                				<styles.ListItem
                  					onClick={onOptionClicked(option.chainName, option.contract )}
                  					value={option.contract}
                					>
                  				{option.chainName}
                				</styles.ListItem>
              				))}
            			</styles.DropDownList>
          			</styles.DropDownListContainer>
        		)}
      		</styles.DropDownContainer>	
			  <p>  Current Value: <strong>{value}</strong>  </p>
        </styles.Container>
  );
}
interface INumberDisplay {
    createDisplayDiv():string;
    isValidNumber(number:string):boolean;
    triNumberBlock(number:string, range:string, divType:string):string;
    commaSeparateNumbers(number:string, isFaction:boolean):string;
    chainBlocksTogether(numberBlock:string, isWholeNumber:boolean, wholeNumberIsZero:boolean):string;
    fractionNumberBlock(wholeNumberIsZero: boolean, numberBlocks: string[]):string;
    wholeNumberBlock(numberBlocks: string[]):string;
} 

export class NumberDisplay implements INumberDisplay {
    numberwip:string;
    constructor(inputNumber:string){
        this.numberwip = inputNumber.split(".").length === 1?inputNumber = inputNumber + ".0": inputNumber;
    }

    public createDisplayDiv():string{
        let returnDiv = ""
        if(!this.isValidNumber(this.numberwip)){return "error"}
        //this.numberwip = this.largeNumberFormat(this.numberwip);
        returnDiv= returnDiv + this.chainBlocksTogether(this.numberwip.split(".")[0], true, false)
        returnDiv = returnDiv + "<div class='box primary'>.</div>";
        returnDiv= returnDiv + this.chainBlocksTogether(this.numberwip.split(".")[1], false, this.numberwip.split(".")[0] === "0"?true:false )
        
        return returnDiv;
    }

    public isValidNumber(numberwip:string ="0.0"):boolean{
        numberwip = numberwip.toString().replace(/,/g, '');
        let result = true;
        if(numberwip.split(".").length !== 2){return false;}
        if(!this.isNumeric(numberwip.split(".")[0]) || !this.isNumeric(numberwip.split(".")[1])){result = false;}
        if(numberwip.split(".")[0][0] === "0" && numberwip.split(".")[0].length > 1){result = false;}
        if(numberwip.split(".")[0].trim() === "" || numberwip.split(".")[1].trim() === ""){result = false;}
        return result; 
    }

    private isNumeric(str:string) {
        const pattern = /^\d+(\.\d+)?$/; 
        return pattern.test(str);
    }

    public triNumberBlock(number:string, range:string, divType:string):string{
        if(divType === "primary"){
            if(range === "thousand"){range = ",";}
            if (range !== "hundred"){number = number + " " + range;}
            range = "";
            }
        if(divType === "first" && range === "hundred"){ range = ""; }	
        return "<div class='box " + divType + " " + range + "'>" + number + "</div>";
    }    

    public commaSeparateNumbers(numberwip:string, isFaction:boolean):string{
        let buildnumber = "";
        let isTrailingZero = isFaction;
        for (let i = numberwip.length - 1; i >= 0; --i) {
            if(numberwip.charAt(i) !== "0") { isTrailingZero = false };
            if(isTrailingZero){
                buildnumber = i=== 0?"0":"";
                
            } else {
                buildnumber = numberwip.charAt(i) + buildnumber;
            }
        }
        numberwip = buildnumber;
        if (parseInt(numberwip) === 0) {return "0";}
        buildnumber = "";
        if (isFaction){ 
            let xFill = numberwip.length % 3
            if (xFill >0) {numberwip = numberwip + (xFill === 2? "x": "xx") }
        }
            let j = 1;
            for (let i = numberwip.length - 1; i >= 0; --i) {
                buildnumber = numberwip.charAt(i) + buildnumber;
                if((i !== (numberwip.length - 1) && (i !== 0)) && j % 3 === 0){buildnumber = "," + buildnumber;} 
                j= j + 1;
            }
        buildnumber = buildnumber.replace(/x/g, '');
        numberwip = buildnumber;
        return numberwip;
    }

    public chainBlocksTogether(numberBlock:string, isWholeNumber:boolean, wholeNumberIsZero:boolean):string{
        let result = "";
        let numberBlocks = this.commaSeparateNumbers(numberBlock, !isWholeNumber).split(",");
        if(isWholeNumber){	
            result = this.wholeNumberBlock(numberBlocks);
        } else {
            
            result = this.fractionNumberBlock(wholeNumberIsZero, numberBlocks);		
        }			
        return result; 
    }    

    public fractionNumberBlock(wholeNumberIsZero: boolean, numberBlocks: string[]):string {
        let result = ""
        let nbl = numberBlocks.length;
        let availableRange = ["kwei", "mwei", "gwei", "microether", "ether", "mether"];

        if (wholeNumberIsZero && numberBlocks.length > 1) {
            let zeroCount = 0;
            let isnonZero = true;
            let i: number;
            for (i = 0; i < nbl; i++) {
                for (let j = 0; j < 3; j++) {
                    numberBlocks[i][j] === "0" ? zeroCount = zeroCount + 1 : isnonZero = false;
                    if (!isnonZero) { j = 3; i = nbl; }
                }
            }
            if(zeroCount >0){
                result = result + this.triNumberBlock("x" + zeroCount, "zerox", i === 0 ? "primary" : i === 1 ? "first" : "smallbox");
            }
            for (i = Math.floor(zeroCount / 3); i < nbl; i++) {

                if (zeroCount > 0 &&  i === Math.floor(zeroCount / 3)) {
                    let block = numberBlocks[i];
                    block = numberBlocks[i][0] === "0" ? "" : numberBlocks[i][0];
                    if (numberBlocks[i].length > 1) { block = block + numberBlocks[i][1] === "0" ? "" : numberBlocks[i][1]; };
                    if (numberBlocks[i].length > 2) { block = block + numberBlocks[i][2]; };
                    numberBlocks[i] = block;
                }
                result = result + this.triNumberBlock(numberBlocks[i], availableRange[i], "smallbox");
            }
        } else {
            for (let i = 0; i < nbl; i++) {
                result = result + this.triNumberBlock(numberBlocks[i], availableRange[i], "smallbox");
            }
        }
        return result;
    }

    public wholeNumberBlock(numberBlocks: string[]):string {
        let result = "";
        let availableRange = ["quadrillion", "trillion", "billion", "million", "thousand", "hundred"];

        for (let i = 0; i < numberBlocks.length; ++i) {
            result = result + this.triNumberBlock(
                                numberBlocks[i], 
                                availableRange[(availableRange.length) - (numberBlocks.length - i)], 
                                i === 0 ? "primary" : i === 1 ? "first" : "smallbox"
                              );
        }
        return result;
    }
}
import { Tooltip } from 'react-tooltip';


import React from 'react';
import { styles } from '../style/style';
import infoImage from '../images/info.png';
import { NumberDisplay } from '../utilities/NumberDisplay';

export default function ContractLine(props: any) {
	let displayNumber = new NumberDisplay(props.callValue)

	const textRequired =
	 displayNumber.createDisplayDiv();

	// TODO this is duplication
	const createMarkup = (text: any) => {
		return {
		  __html: text
		};	
	};

    return (
		<styles.TimeFeeRow>
			<styles.TimeFeeRowEnds>
				<span data-tooltip-id="have-to-claim" data-tooltip-content={props.tooltip}>
					<styles.TimeFeeRowInfo src={infoImage} alt="info button" />
				</span>
				<Tooltip id="have-to-claim" />
				<styles.TimeFeeRowTitle>{props.title}</styles.TimeFeeRowTitle>
			</styles.TimeFeeRowEnds>
			<styles.TimeFeeRowEnds>
				<div dangerouslySetInnerHTML={createMarkup(textRequired)} />
				<div className="src-components-DappRow__currency">{props.currency}</div>
			</styles.TimeFeeRowEnds>
			<div  />
		</styles.TimeFeeRow>
    )

}
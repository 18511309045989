import { styles } from '../style/style';
import { useState } from "react";
import emailjs from "emailjs-com";
import ContractLine from '../Components/ContractLine';

interface ButtonState {
  color: string;
  text: string;
}

interface InputState {
  displayText: string;
  errorText: string;
}

export default function DevLicenceClub() {

  // TODO this is duplication
  const createMarkup = (text: any) => {
		return {
		  __html: text
		};	
	};

  const [inputNumber, setInputNumber] = useState('');
  const [inputTitle, setInputTitle] = useState('');
  const [inputToolTip, setInputToolTip] = useState('');
  const [inputCurrency, setInputCurrency] = useState('');

  const [inputName, setInputName] = useState<InputState>({
    displayText: '',
    errorText: ''
  });
  const [inputContact, setInputContact] = useState<InputState>({
    displayText: '',
    errorText: ''    
  });
  const [inputChatText, setInputChatText] = useState<InputState>({
    displayText: '',
    errorText: ''    
  });
  const [sentText, setSentText] = useState("");
  const [buttonState, setButtonState] = useState<ButtonState>({
    color: 'blue',
    text: 'Submit'
  });

  const handleSubmit =(event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let textValidation = true;
    if(inputName.displayText === ""){
      textValidation = false;
      setInputName({displayText:'', errorText: '* Please Enter a Name' });
    }
    if(inputContact.displayText === ""){
      textValidation= false;
      setInputContact({displayText:'', errorText: '* Please Enter a Contact' });
    }
    if(inputChatText.displayText === ""){
      textValidation = false;
      setInputChatText({displayText:'', errorText: '* Please Enter a Chat Message' });
    }
    let messageText = "Thank you for getting in touch.<br>";
    messageText = messageText + "Contact Name: " + inputName.displayText + "<br>";
    messageText = messageText + "Your Contact Details:" + inputContact.displayText + "<br>"; 
    messageText = messageText + "Your Message:" + inputChatText.displayText;
    if(buttonState.color === "blue" && textValidation) {
      setButtonState({color: "red", text: "Re Set"});
      console.log( messageText );
      emailjs.send("service_bin5u6e","template_afrn6ar",{
        message: messageText,
        },"zVyx7wNxPNLH_ThMk")
        .then(function(){
          setSentText(messageText);
        }, function(error){
          setSentText("Sorry about this but an error occured");
        });
        setInputName({displayText: '', errorText: ''});
        setInputContact({displayText: '', errorText: ''});
        setInputChatText({displayText: '', errorText: ''});
    } else if(buttonState.color === "red")  {
      setButtonState({color: "blue", text: "Submit"});
      setInputName({displayText: '', errorText: ''});
      setInputContact({displayText: '', errorText: ''});
      setInputChatText({displayText: '', errorText: ''});
      setSentText("");
    }
  }

  return (
    <styles.Container>
      <styles.BannerTitle>Our Code for Your Project</styles.BannerTitle>
      <styles.BannerSubTitle>
        Get your licence for only a few dollars 
      </styles.BannerSubTitle>      
      <styles.BannerSubTitle>
        Pay with any currency including crypto
      </styles.BannerSubTitle>
      <styles.BannerSubTitle>
        Display Simple Large Numbers
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>
        The module demonstrated below is available to the community in the form of source code.
        I can also implement the solution for you directly into your web solution.
      </styles.GeneralParagragh>      
      <styles.GeneralParagragh>
        This widget takes Title, Tool Tip, Currency and Number parameters. You can enter your own text in the boxes. 
      </styles.GeneralParagragh>
      <styles.BannerSubTitle>
        Give it a go.
      </styles.BannerSubTitle>
      <styles.GeneralParagragh>Enter Number Title</styles.GeneralParagragh>
      <div>
        <input type="text" onChange={(e) => setInputTitle(e.target.value)} />
      </div>
      <styles.GeneralParagragh>Enter Tooltip Text</styles.GeneralParagragh>
      <div>
        <input type="text" onChange={(e) => setInputToolTip(e.target.value)} />
      </div>         
      <styles.GeneralParagragh>Enter Currency Symbol</styles.GeneralParagragh>
      <div>
        <input type="text" onChange={(e) => setInputCurrency(e.target.value)} />
      </div>           
      <styles.GeneralParagragh>Enter a number</styles.GeneralParagragh>
      <div>
        <input type="text" onChange={(e) => setInputNumber(e.target.value)} />
      </div>     
      <ContractLine 
			  title={inputTitle} 
			  tooltip={inputToolTip} 
			  callValue={inputNumber}
        currency={inputCurrency}
		  />
      <styles.BannerTitle>Get in touch for a chat about Licencing</styles.BannerTitle>
      <styles.BannerSubTitle>
        Please feel free to fill out some basic contact details so I can get back to you for a chat.
      </styles.BannerSubTitle> 
      <styles.GeneralParagragh dangerouslySetInnerHTML={createMarkup(sentText)}></styles.GeneralParagragh>
      <styles.GeneralParagragh>Contact Name</styles.GeneralParagragh>
      <div>
        <styles.ErrorMessage>{inputName.errorText}</styles.ErrorMessage>
        <input 
          type="text" 
          onChange={(e) => setInputName({displayText: e.target.value, errorText: ''})}
          value={inputName.displayText}  
        />
      </div>
      <styles.GeneralParagragh>Email, Telegram or other form of contact address</styles.GeneralParagragh>
      <div>
      <styles.ErrorMessage>{inputContact.errorText}</styles.ErrorMessage>
        <input 
          type="text" 
          onChange={(e) => setInputContact({displayText: e.target.value, errorText: ''})}
          value = {inputContact.displayText}
        />
      </div>              
      <styles.GeneralParagragh>Any message to get started </styles.GeneralParagragh>
      <div>
      <styles.ErrorMessage>{inputChatText.errorText}</styles.ErrorMessage>
        <textarea 
          rows={4}
          cols={40}
          onChange={(e) => setInputChatText({displayText: e.target.value, errorText: ''})} 
          value={inputChatText.displayText}
        />
      </div>    
      <div>
        <styles.SendMessageButton color={buttonState.color} onClick={handleSubmit}>
          {buttonState.text}
        </styles.SendMessageButton>
      </div>
    </styles.Container>
  )
}